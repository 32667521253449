<template>
  <a href="#" @click.prevent="toggle"><slot/></a>
</template>

<script>
import EventBus from '../../javascript/common/event-bus.js';

export default {
  name: 'EventTrigger',

  props: {
    event: { type: String, required: true },
    eventData: { type: Object, required: false, default: () => ({}) }
  },

  methods: {
    toggle () {
      EventBus.$emit(this.event, this.eventData || {});
    }
  }

};
</script>
